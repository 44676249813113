<template>
  <PageContainer :title="$translations.patients.form.title">
    <template v-slot:content>
      <PatientForm />
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer } from '@seliaco/red-panda'
import PatientForm from './components/PatientForm.vue'

export default {
  name: 'PatientFormView',
  components: {
    PageContainer,
    PatientForm
  }
}
</script>
